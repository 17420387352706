import { getCurrentTimeZone } from '../../schedule/utils/time_utils'

export const API_URL = window.location.origin

export const HEADERS = {
  'AD-Authorization': localStorage.getItem('AD-Authorization'),
  'Content-Type': 'application/json',
  Accept: 'application/json',
}

export const FILE_HEADERS = {
  'AD-Authorization': localStorage.getItem('AD-Authorization'),
  'Content-Type': 'multipart/form-data',
  Accept: 'application/json',
}

export const FORM_HEADERS = {
  'AD-Authorization': localStorage.getItem('AD-Authorization'),
  'Content-Type': 'multipart/form-data',
  Accept: 'application/json',
}

export const API_ROUTES = {
  bookmarks: '/api/bookmarks',
  recent_searches: '/api/recent_searches',
  recent_search: (recentSearchId) => `/api/recent_searches/${recentSearchId}`,
  bookmark: (bookmarkId) => `/api/bookmarks/${bookmarkId}`,
  news_bookmarks: '/api/news_bookmarks',
  news_bookmark: (bookmarkId) => `/api/news_bookmarks/${bookmarkId}`,
  newsContent: (newsId) => `/api/news_content/${newsId}`,
  videoResourcesInfo: (episodeId) => `/api/episodes/${episodeId}/info`,
  link: (episodeId, linkId) => `/api/episodes/${episodeId}/links/${linkId}`,
  links: (episodeId) => `/api/episodes/${episodeId}/links`,
  image: (episodeId, imageId) => `/api/episodes/${episodeId}/images/${imageId}`,
  images: (episodeId) => `/api/episodes/${episodeId}/images`,
  alchemy_picture: (pictureId) => `/admin/api/pictures/${pictureId}`,
  alchemy_ebook: (ebookId) => `/admin/api/ebooks/${ebookId}`,
  transcript: (episodeId, transcriptId) =>
    `/api/episodes/${episodeId}/transcripts/${transcriptId}`,
  transcripts: (episodeId) => `/api/episodes/${episodeId}/transcripts`,
  publicTranscripts: (episodeId) =>
    `/api/episodes/${episodeId}/transcripts/public`,
  generateTranscript: (episodeId) =>
    `/api/episodes/${episodeId}/transcripts/autogenerate`,
  document: (episodeId, documentId) =>
    `/api/episodes/${episodeId}/documents/${documentId}`,
  documents: (episodeId) => `/api/episodes/${episodeId}/documents`,
  ebook: (episodeId, ebookId) => `/api/episodes/${episodeId}/ebooks/${ebookId}`,
  ebooks: (episodeId) => `/api/episodes/${episodeId}/ebooks`,
  guide: (episodeId, guideId) => `/api/episodes/${episodeId}/guides/${guideId}`,
  guides: (episodeId) => `/api/episodes/${episodeId}/guides`,
  notes: '/api/notes',
  note: (noteId) => `/api/notes/${noteId}`,
  tags: '/api/tags',
  comments: '/api/comments',
  report_reasons: '/api/report_reasons',
  comment: (commentId) => `/api/comments/${commentId}`,
  comment_reports: (commentId) => `/api/comments/${commentId}/reports`,
  bookmark_episodes: '/api/bookmarks/episodes/',
  bookmark_tags: '/api/bookmarks/tags/',
  bookmark_news: '/api/news_bookmarks/news/',
  news_bookmark_tags: '/api/news_bookmarks/tags/',
  note_episodes: '/api/notes/episodes/',
  watch_history: '/api/watch_history/',
  watch_history_item: (episodeId) => `/api/watch_history/${episodeId}`,
  episode: (episodeId) => `/api/episodes/${episodeId}`,
  news: (newsId) => `/api/news/${newsId}`,
  article: (articleId) => `/api/articles/${articleId}`,
  printArticle: (articleId) => `/api/articles/${articleId}.pdf`,
  tutorial: '/api/tutorial',
  program: (dateMilliseconds) => `/api/programs/${dateMilliseconds}`,
  programs: '/api/programs',
  search: '/api/search',
  settings: '/api/settings',
  programsDates: '/api/programs_dates',
  notifications: '/api/notifications',
  print_schedule: (dateFrom, dateTo, timezone) =>
    `/print_schedule.pdf?date_from=${dateFrom}&date_to=${dateTo}&timezone=${timezone}&client_timezone=${getCurrentTimeZone()}`,
  admin_upload_media: '/api/admin/medias/imports',
  admin_medias_sas: '/api/admin/medias/sas',
  admin_upload_video_sku: (id) => `/api/admin/videos/skus/${id}`,
  admin_upload_jobs_status: (id) => `/api/admin/jobs/statuses/${id}`,
  admin_featured_videos: '/admin/api/featured_videos',
  admin_featured_video: (id) => `/admin/api/featured_videos/${id}`,
  featured_video: (id) => `/api/featured_videos/${id}`,
  like: (episode_id) => `/api/episodes/${episode_id}/like`,
  banners: (bannerId) => `/api/banners/${bannerId}`,
}

export const BASE_API_CONFIG = {
  baseURL: API_URL,
  headers: HEADERS,
}

export const FILE_UPLOAD_CONFIG = {
  baseURL: API_URL,
  headers: FILE_HEADERS,
}

export function config(onProgress) {
  return {
    onUploadProgress: (progressEvent) => {
      if (onProgress) {
        const progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        )
        onProgress(progress)
      }
    },
    ...FILE_UPLOAD_CONFIG,
  }
}
