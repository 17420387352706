<template>
  <div>
    <div class="entries-carousel-container">
      <div
        v-if="currentIndex !== 0"
        class="entries-carousel-button entries-carousel-button__left"
        :class="{ 'entries-carousel-button__light': isLight }"
        @click="scrollLeft"
      >
        {{ BTN_LEFT }}
      </div>
      <div class="entries-carousel" :style="carouselStyle">
        <component
          :is="getComponentName()"
          v-for="item in items"
          :key="item.id"
          :item-id="item.id"
          :title="item.title"
          :title-alt="item.title_alt"
          :thumbnail="item.thumbnail_url"
          :image-link="item.image_link"
          :description="item.description"
          :subject="item.subject"
          :author="item.author"
          :cover-url="item.cover_url"
          :slug="item.slug"
          :series-slug="item.series_slug"
          :topics="item.topics"
          :exclusive="item.exclusive"
          :premiering="item.premiering"
          :live-at-label="item.live_at_label"
          :countdown-tooltip="item.countdown_tooltip"
          :settings="settings"
          :search-query="query"
          :is-light="isLight"
          :episodes-count="item.episodes_count"
          :is-series="item.is_series"
          :sequence-number="item.sequence_number"
          :series-label="item.series_label"
          class="entries-carousel-item"
          @click="showEbook(item)"
        />
        <router-link
          v-if="totalCount > 10"
          :to="{
            name: getRouteTab(),
            query: {
              query: query,
            },
          }"
          class="all-card"
          :class="{ 'all-card__light': isLight }"
        >
          {{ messages.VIEW_ALL }}
        </router-link>
      </div>
      <div
        v-if="currentIndex !== items.length - skip && items.length > 4"
        class="entries-carousel-button entries-carousel-button__right"
        :class="{ 'entries-carousel-button__light': isLight }"
        @click="scrollRight"
      >
        {{ BTN_RIGHT }}
      </div>
    </div>
    <PreviewDialog
      v-if="currentEbook"
      :theme="theme"
      :entity="currentEbook"
      @[EMITTERS.CLOSE_DIALOG_EMITTER]="closePreview"
    />
  </div>
</template>

<script>
import VideoCard from './VideoCard'
import { EMITTERS } from '../../shared/utils/emitters'
import NewsCard from './NewsCard'
import ArticleCard from './ArticleCard'
import EbookCard from './EbookCard'
import { MESSAGES } from '../utils/localization_util'
import { COMPONENTS_MAPPING } from '../utils/name_util'
import PreviewDialog from '../../admin/video_resources/components/PreviewDialog'
import { THEMES as THEME } from '../../shared/utils/view_options'

export default {
  name: 'EntriesCarousel',
  components: { ArticleCard, VideoCard, NewsCard, EbookCard, PreviewDialog },
  props: {
    items: {
      default: () => [],
      type: Array,
      required: true,
    },
    totalCount: {
      default: 0,
      type: Number,
      required: true,
    },
    type: {
      default: 'videos',
      type: String,
      required: true,
    },
    query: {
      default: '',
      type: String,
      required: true,
    },
    settings: {
      required: true,
      default: () => ({
        watch_host: '',
        main_host: '',
        events_host: '',
      }),
      type: Object,
    },
    isLight: {
      default: false,
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      EMITTERS,
      BTN_LEFT: '<',
      BTN_RIGHT: '>',
      messages: MESSAGES,
      route_prefix: 'search-',
      skip: 3,
      currentIndex: 0,
      translateX: 0,
      currentEbook: null,
    }
  },
  computed: {
    carouselStyle() {
      return {
        transform: `translateX(${this.translateX}px)`,
      }
    },
    theme() {
      return this.isLight ? THEME.LIGHT : THEME.DARK
    },
  },
  watch: {
    currentIndex(newIndex) {
      if (newIndex < 0) {
        this.currentIndex = 0
      } else if (newIndex > this.items.length - this.skip) {
        this.currentIndex = this.items.length - this.skip
      }
    },
  },
  methods: {
    showEbook(ebook) {
      if (this.type === 'ebooks') this.currentEbook = ebook
    },
    closePreview() {
      this.currentEbook = null
    },
    scrollLeft() {
      if (this.currentIndex > 0) {
        this.currentIndex--
        this.translateX += this.getJumpSize()
      }
    },
    scrollRight() {
      if (this.currentIndex < this.items.length - this.skip) {
        this.currentIndex++
        this.translateX -= this.getJumpSize()
      }
    },
    getJumpSize() {
      return this.getItemWidth() + this.getJumpByWidth()
    },
    getJumpByWidth() {
      const width = document.body.clientWidth

      if (width >= 768 && width <= 991) return 64

      if (width >= 576 && width <= 767) return 98

      if (width <= 575) return 105

      return 32
    },
    getItemWidth() {
      return this.$el.querySelector('.entries-carousel-item').offsetWidth
    },
    getRouteTab() {
      return `${this.route_prefix}${this.type}`
    },
    getComponentName() {
      return COMPONENTS_MAPPING[this.type]
    },
  },
}
</script>

<style lang="scss" scoped>
.entries-carousel-container {
  width: 100%;
  overflow: hidden;
  position: relative;
  margin: 20px 0;
}

.entries-carousel {
  display: flex;
  flex-wrap: nowrap;
  transition: transform 0.3s ease-in-out;
  gap: 29px;
}

.entries-carousel-item {
  flex: 0 0 auto;
  width: calc((100% - 60px) / 4);
  max-width: 260px;
  min-width: 200px;
}

.articles-section .entries-carousel-button {
  top: 19px;
}

.entries-carousel-button {
  position: absolute;
  display: flex;
  cursor: pointer;
  width: 64px;
  height: 140px;
  top: 0;
  padding: 50px 22px;
  font-size: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: #ffffff;
  background: rgba(11, 9, 15, 0.8);
  box-shadow: 0 4px 40px 0 rgba(167, 167, 167, 0.15);
  z-index: 10;
}

.entries-carousel-button__left {
  left: 0;
}

.entries-carousel-button__right {
  right: 0;
}

.entries-carousel-button__light {
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(4px);
  color: #131118;
}

.all-card {
  flex: 0 0 24%;
  min-width: 200px;
  max-width: 260px;
  height: 140px;
  background-color: #32303c;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 128%;
}

.all-card__light {
  background-color: #8f8a9b;
}
</style>
