import EpisodeSelectorApp from './episode_selector/App'
import StudyToolsApp from './study_tools/App'
import VideoResourcesMobileApp from './study_tools/mobile_video_resources_app/App'
import VideoResourcesApp from './admin/video_resources/App'
import TranscriptsApp from './admin/transcripts/App'
import AdminArticleApp from './admin/article/App'
import MobileArticleApp from './mobile_article/App'
import ProfileApp from './profile/App'
import ProfileNewsBookmarksApp from './profile_news_bookmarks/App'
import ArticleApp from './article/App'
import EpisodeTranscriptApp from './episode_transcript/App'
import MobileEpisodeTranscriptApp from './mobile_episode_transcript/App'
import ProfileNotesApp from './profile_notes/App'
import NotificationApp from './notification/App'
import RokuApp from './roku/App'
import ScheduleApp from './schedule/App'
import MessageCenterApp from './message_center/App'
import ProfileWatchHistoryApp from './profile_watch_history/App'
import studyToolsStore from './stores/study_tools_store'
import videoResourcesRouter from './routers/video_resources_router'
import studyToolsRouter from './routers/study_tools_router'
import studyToolsNotesRouter from './routers/study_tools_notes_router'
import studyToolsBookmarksRouter from './routers/study_tools_bookmarks_router'
import NewsBookmarksApp from './news_bookmarks/App'
import NewsContentApp from './news_content/App'
import newsBookmarksRouter from './routers/news_bookmarks_router'
import adminMediaUploadApp from './admin/medias/App'
import searchBarApp from './search_bar/App'
import searchApp from './search/App'
import searchRouter from './routers/search_router'
import featuredVideosAdmin from './admin/featured_videos/App'
import newsFeaturedVideos from './news/featured_videos/App'
import likesApp from './watch/likes/App'
import manageBannerApp from './manage_banners/App'
import previewDonateBannerApp from './donate_banner_preview/App'
import ebookPreviewApp from './ebook_preview_app/App'

const VueAppMappings = {
  '#episodes-selector': {
    app: EpisodeSelectorApp,
  },
  '#study-tools-app': {
    app: StudyToolsApp,
    store: studyToolsStore,
    router: studyToolsRouter,
  },
  '#mobile-video-resources-app': {
    app: VideoResourcesMobileApp,
    store: studyToolsStore,
    router: studyToolsRouter,
  },
  '#video-resources-app': {
    app: VideoResourcesApp,
    router: videoResourcesRouter,
  },
  '#profile-bookmarks-app': {
    app: ProfileApp,
    router: studyToolsBookmarksRouter,
  },
  '#profile-news-bookmarks-app': {
    app: ProfileNewsBookmarksApp,
    store: studyToolsStore,
    router: newsBookmarksRouter,
  },
  '#profile-notes-app': {
    app: ProfileNotesApp,
    store: studyToolsStore,
    router: studyToolsNotesRouter,
  },
  '#profile-watch-history-app': {
    app: ProfileWatchHistoryApp,
  },
  '#flash-notifications-app': {
    app: NotificationApp,
  },
  '#roku-app-carousel': {
    app: RokuApp,
  },
  '#satellite-schedule-app': {
    app: ScheduleApp,
  },
  '#message-center-app': {
    app: MessageCenterApp,
    webSocketEnabled: true,
  },
  '#search-bar-app': {
    app: searchBarApp,
  },
  '#search-bar-app-mobile': {
    app: searchBarApp,
  },
  '#search-app': {
    app: searchApp,
    router: searchRouter,
  },
  '#transcripts-app': {
    app: TranscriptsApp,
  },
  '#admin-article-app': {
    app: AdminArticleApp,
  },
  '#mobile-article-app': {
    app: MobileArticleApp,
  },
  '#article-app': {
    app: ArticleApp,
  },
  '#episode-transcript-app': {
    app: EpisodeTranscriptApp,
  },
  '#mobile-episode-transcript-app': {
    app: MobileEpisodeTranscriptApp,
  },
  '#news-bookmarks-app': {
    app: NewsBookmarksApp,
  },
  '#news-content-app': {
    app: NewsContentApp,
  },
  '#featured-videos-app': {
    app: featuredVideosAdmin,
  },
  '#news-featured-videos-app': {
    app: newsFeaturedVideos,
  },
  '#admin-media-upload-app': {
    app: adminMediaUploadApp,
  },
  '#likes-app': {
    app: likesApp,
  },
  '#manage-banner-app': {
    app: manageBannerApp,
  },
  '#ebook-preview-app': {
    app: ebookPreviewApp,
  },
  '#donate-banner-preview-app': {
    app: previewDonateBannerApp,
  },
}

export default VueAppMappings
