<template>
  <PreviewDialog v-if="ebook"
                 :theme="theme"
                 :entity="ebook"
                 @[EMITTERS.CLOSE_DIALOG_EMITTER]="closePreview"
  />
</template>

<script>
import EbookPreview from '../admin/video_resources/components/EbookPreview.vue'
import { THEMES } from '../shared/utils/view_options'
import { EMITTERS } from '../shared/utils/emitters'
import PreviewDialog from '../admin/video_resources/components/PreviewDialog.vue'

export default {
  name: 'NewsContentAppComponent',
  components: {
    PreviewDialog,
    EbookPreview,
  },
  data() {
    return {
      EMITTERS,
      theme: THEMES.LIGHT,
      ebook: null,
      ebooks: [],
    }
  },
  mounted() {
    this.ebooks = JSON.parse(this.optional_params.ebooks).map((ebook) =>
      JSON.parse(ebook)
    )
    $('.' + this.optional_params.element_class).click((event) => {
      event.preventDefault()
      event.stopPropagation()
      this.showEbook($(event.currentTarget).attr('ebook-id'))
    })
  },
  methods: {
    showEbook(ebookId) {
      const ebook = this.ebooks.find((ebook) => ebook.id == parseInt(ebookId))
      if (!ebook) return

      this.ebook = ebook
    },
    closePreview() {
      this.ebook = null
    },
  },
}
</script>