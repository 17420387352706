import { Quill } from '@vueup/vue-quill'

import CustomLinkSanitizer from './custom_link_sanitizer'

function updateTooltipPosition(tooltip, element, container) {
  const bounds = element.getBoundingClientRect()
  const containerBounds = container.getBoundingClientRect()

  let leftBorderLimit = containerBounds.left
  let rightBorderLimit = containerBounds.right

  // Tooltip left edge X.
  let x = bounds.left - leftBorderLimit - tooltip.offsetWidth / 2

  let topGap = bounds.height
  if (bounds.top - containerBounds.top > containerBounds.height / 2) {
    topGap = -tooltip.getBoundingClientRect().height
  }

  // Corrects left edge.
  if (x * -1 > leftBorderLimit) {
    x = x + (x * -1 - leftBorderLimit)
  }

  // Tooltip right edge X.
  let widthX = x + tooltip.offsetWidth + 2

  // Corrects right edge.
  if (widthX > rightBorderLimit) {
    x = x - (widthX - rightBorderLimit)
  }

  tooltip.style.top = bounds.top - containerBounds.top + topGap + 'px'
  tooltip.style.left = x + 'px'
}

function removeTooltips(container) {
  const oldTooltips = container.getElementsByClassName('ad-definition-tooltip')
  for (const el of oldTooltips) {
    el.remove()
  }
}

let _touts = []

const Inline = Quill.import('blots/inline')

class Definition extends Inline {
  static create(definition) {
    let node = super.create()
    if (definition.value) {
      node.setAttribute('definition', definition.value)
      node.classList.add('ad-definition')
    }
    if (definition.link) {
      // Sanitize url if desired
      const link = CustomLinkSanitizer.sanitize(definition.link)
      node.setAttribute('href', link)
    }
    node.setAttribute('target', '_blank')

    return node
  }

  static formats(node) {
    let data = node.getAttribute('definition')
    let link = node.getAttribute('href')
    if (link && link === '') link = null

    if (typeof data !== 'undefined') return { value: data, link: link }

    return {}
  }

  formatAt(index, length, name, value) {
    if (name === 'script') return

    super.formatAt(index, length, name, value)
  }

  position() {}

  static initDrawTooltip(node) {
    let _tooltip = null
    if (
      !node.getAttribute('definition') ||
      node.getAttribute('definition') === 'null'
    ) {
      return
    }

    const drawTooltip = () => {
      let tooltip = document.createElement('DIV')
      let closeIcon = document.createElement('DIV')
      let tooltipTitle = document.createElement('DIV')
      let link = document.createElement('A')
      const container = node.closest('.ql-container')

      tooltip.classList.add('ad-definition-tooltip')
      tooltip.innerHTML = formattedBody(node)

      tooltipTitle.classList.add('ad-definition-tooltip__title')
      tooltipTitle.innerText = node.innerText

      closeIcon.classList.add('ad-definition-tooltip__close-icon')
      closeIcon.innerText = '✕'
      closeIcon.addEventListener('click', () => {
        removeTooltips(node.closest('.ql-container'))
      })

      if (_touts.length > 0) {
        _touts.forEach((_tout) => {
          clearTimeout(_tout)
        })
        _touts.length = 0
      }
      removeTooltips(container)
      tooltip.appendChild(closeIcon)
      tooltip.prepend(tooltipTitle)
      if (node.href && node.href !== '') {
        link.href = node.href
        link.setAttribute('target', '_blank')
        link.classList.add('ad-definition-tooltip__link')
        link.innerText = 'Open link'
        tooltip.appendChild(link)
      }

      container.appendChild(tooltip)
      updateTooltipPosition(tooltip, node, container)

      _tooltip = tooltip
      _tooltip.addEventListener('mouseleave', () => {
        hideTooltip(_tooltip, node)
      })
    }

    node.addEventListener('mouseover', drawTooltip)
    node.addEventListener('click', (event) => {
      const vw = Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      )
      if (vw > 991 && node.href) return

      event.stopPropagation()
      event.preventDefault()

      drawTooltip(event)
    })
    node.addEventListener('mouseleave', () => {
      hideTooltip(_tooltip, node)
    })
  }
}


function hideTooltip(_tooltip, node) {
  if ($(node).is(':hover')) return

  if (_tooltip) {
    if ($(_tooltip).is(':hover')) return

    _tooltip.classList.add('ad-definition-tooltip__fade')
  }
  const _tout = setTimeout(() => {
    removeTooltips(node.closest('.ql-container'))
  }, 500)
  _touts.push(_tout)
}


function formattedBody(node) {
  let body = node.getAttribute('definition')

  body.match(/http(s)*:\/\/[^ ]+/gi).forEach((link) => {
    body = body.replace(link, `<a href="${link}">${link}</a>`)
  })

  return body
}

Definition.blotName = 'ad-definition'
Definition.tagName = 'a'

export default Definition
