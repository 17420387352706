export const SEARCH_TABS = {
  videos: 'Videos',
  prophetic_news: 'Prophecy News',
  articles: 'Articles',
  ebooks: 'Ebooks and Study Guides',
  all: 'All',
}

export const SECTION_TYPES = ['videos', 'prophetic_news', 'articles', 'ebooks']

export const COMPONENTS_MAPPING = {
  videos: 'VideoCard',
  prophetic_news: 'NewsCard',
  articles: 'ArticleCard',
  ebooks: 'EbookCard',
}
