<template>
  <div class="news-card-block">
    <a class="card-link" :title="title" :href="newsUrl()">
      <img class="thumbnail" :alt="title" :src="imageLink" />
      <span class="card-title" :class="{ 'card-title__light': isLight }">
        {{ title }}
      </span>
    </a>
    <div class="topics-block">
      <span
        v-for="topic in topics"
        :key="topic.id"
        class="topic"
        :class="{ topic__light: isLight }"
      >
        <a class="topic-link" :title="topic.name" :href="getTopicUrl(topic)">
          {{ topic.name }}
        </a>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewsCard',
  props: {
    title: {
      required: true,
      default: '',
      type: String,
    },
    imageLink: {
      required: true,
      default: '',
      type: String,
    },
    slug: {
      required: true,
      default: '',
      type: String,
    },
    topics: {
      required: false,
      default: () => [],
      type: Array,
    },
    settings: {
      required: false,
      default: () => ({
        watch_host: '',
        main_host: '',
        events_host: '',
      }),
      type: Object,
    },
    isLight: {
      required: true,
      default: true,
      type: Boolean,
    },
  },
  methods: {
    newsUrl() {
      const protocol = window.location.protocol
      const host = this.settings.main_host

      return `${protocol}//${host}/prophecy-news/${this.slug}?search_from=web`
    },
    getTopicUrl(topic) {
      return `/topic/${topic.slug}`
    },
  },
}
</script>

<style lang="scss" scoped>
.news-card-block {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 200px;
  max-width: 260px;
  flex: 1;

  .thumbnail {
    max-height: 140px;
    width: 100%;
    object-fit: cover;
    font-family: 'object-fit: cover;', serif;
    margin-bottom: 8px;
  }
}

.news-card__content {
  display: flex;
  flex-direction: column;
}

.card-title {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  font-family: 'DM Sans', sans-serif !important;
  cursor: pointer;
}

.card-title__light {
  color: #323232;
}

.card-title-alt {
  margin-top: 4px;
  color: #a6a6a6;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.01em;
  font-family: 'DM Sans', sans-serif !important;
}

.card-title-alt__light {
  color: #5c5c5c;
}

.topics-block {
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  gap: 6px;
}

.topic {
  padding: 4px 10px 4px;
  font-size: 12px;
  line-height: 16px;
  font-family: 'DM Sans', serif;
  font-style: normal;
  font-weight: 400;
  background-color: #3b3946;
  color: #d1d3d4;
  border: 1px solid #444050;
  box-sizing: border-box;
  border-radius: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.topic__light {
  color: #5c5c5c;
  background-color: #fff;
  border: 1px solid #d1d3d4;
}

.card-link,
.topic-link {
  color: inherit;
  white-space: break-spaces;
  cursor: pointer;
  line-height: 0;
}
</style>
