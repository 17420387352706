import { createApp } from 'vue'
import mitt from 'mitt'
import VueClickAway from 'vue3-click-away'
import ActionCableVue from 'actioncable-vue'
import VueSocialSharing from 'vue-social-sharing'

import VueAppMappings from './vue-app-mappings'
import globalStore from './stores/global_store'
import i18n from './localization/index'

$(document).on('turbolinks:load', () => {
  initApps()
})

$(document).on('turbolinks:before-cache', () => {
  resetApp('#profile-bookmarks-app')
  resetApp('#profile-news-bookmarks-app')
  resetApp('#profile-notes-app')
  resetApp('#profile-watch-history-app')
})

function resetApp(selector) {
  const element = document.querySelector(selector)
  if (!element) return

  $(selector).html('<div class="bookmarks-loader" />')
}

function initApps() {
  const emitter = mitt()

  mountVueApp('#episodes-selector', emitter)
  mountVueApp('#study-tools-app', emitter)
  mountVueApp('#mobile-video-resources-app', emitter)
  mountVueApp('#video-resources-app', emitter)
  mountVueApp('#admin-article-app', emitter)
  mountVueApp('#mobile-article-app', emitter)
  mountVueApp('#article-app', emitter)
  mountVueApp('#profile-bookmarks-app', emitter)
  mountVueApp('#profile-news-bookmarks-app', emitter)
  mountVueApp('#profile-notes-app', emitter)
  mountVueApp('#profile-watch-history-app', emitter)
  mountVueApp('#flash-notifications-app', emitter)
  mountVueApp('#roku-app-carousel', emitter)
  mountVueApp('#satellite-schedule-app', emitter)
  mountVueApp('#message-center-app', emitter)
  mountVueApp('#search-bar-app', emitter)
  mountVueApp('#search-bar-app-mobile', emitter)
  mountVueApp('#search-app', emitter)
  mountVueApp('#transcripts-app', emitter)
  mountVueApp('#episode-transcript-app', emitter)
  mountVueApp('#mobile-episode-transcript-app', emitter)
  mountVueApp('#news-bookmarks-app', emitter)
  mountVueApp('#news-content-app', emitter)
  mountVueApp('#featured-videos-app', emitter)
  mountVueApp('#news-featured-videos-app', emitter)
  mountVueApp('#admin-media-upload-app', emitter)
  mountVueApp('#likes-app', emitter)
  mountVueApp('#manage-banner-app', emitter)
  mountVueApp('#donate-banner-preview-app', emitter)
  mountVueApp('#ebook-preview-app', emitter)
}

function mountVueApp(selector, emitter) {
  const element = document.querySelector(selector)
  if (!element) return

  const optional_params = { ...element.dataset }
  const app = createApp(VueAppMappings[selector].app)

  app.config.globalProperties.dataset = element.dataset

  $(selector).empty()

  app.config.globalProperties.emitter = emitter
  app.config.globalProperties.optional_params = optional_params
  app.use(VueClickAway)
  app.use(VueSocialSharing)
  app.use(i18n)
  setupStore(app, selector)
  setupRouter(app, selector)
  setupActionCable(app, selector)
  app.mount(selector)
}

function setupStore(app, selector) {
  app.config.globalProperties.$globalStore = globalStore
  if (!VueAppMappings[selector].store) return

  app.use(VueAppMappings[selector].store)
}

function setupRouter(app, selector) {
  if (!VueAppMappings[selector].router) return

  app.use(VueAppMappings[selector].router)
}

function setupActionCable(app, selector) {
  if (!VueAppMappings[selector].webSocketEnabled) return
  const isProduction = process.env.NODE_ENV === 'production'
  const protocol = isProduction ? 'wss' : 'ws'
  const connectionUrl = `${protocol}://${window.location.host}/api/cable`
  const actionCableVueOptions = {
    debug: true,
    debugLevel: 'error',
    connectionUrl,
    connectImmediately: true,
  }
  app.use(ActionCableVue, actionCableVueOptions)
}
